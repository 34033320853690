import naja from 'naja';
import initBootstrapToasts from './bootstrap-toast';

naja.addEventListener('error', () => {
	const toast = document.createElement('div');
	toast.classList.add('toast', 'bg-danger', 'text-white');
	toast.innerHTML = '<div class="d-flex"><div class="toast-body">Chyba při provádění požadavku. Opakujte akci znova.</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"></button></div>';
	document.querySelector('.toast-container').appendChild(toast);
	initBootstrapToasts();
});
