import Timeout from '../../../utils/Timeout';
import Register from '../../../utils/Register';
import { KeyCodes } from '../../../utils/KeyCodes';
import naja from 'naja';

export default class CartRecalculator {

    constructor ($container) {
        const _this = this;

        // reload content after delay when clicking +/-
        $container.filterAllNodes('.cart-item-quantity-form .js-spinbox-plus, .cart-item-quantity-form .js-spinbox-minus').on('click touchend',
            function (event) {
                _this.reloadWithDelay(500, this);
                event.preventDefault();
            }
        );

        // reload content after delay after leaving input or pressing ENTER
        // but only if value was changed
        $container.filterAllNodes('.cart-item-quantity-form .js-spinbox-input')
            .change(function () {	
                $(this).blur(function () {
                    _this.reloadWithDelay(500, this);
                });
            })
            .keydown(function (event) {
                if (event.keyCode === KeyCodes.ENTER) {
                    _this.reloadWithDelay(0, this);
                    event.preventDefault();
                }
            }).each(function(index, el) {
				$(this).data('original-value', $(el).val());
			}); 
    }

    reload (form) {
		const input = form.find('input.js-spinbox-input');

		if (input.val() != input.data('original-value')) {
			naja.uiHandler.submitForm(form[0]);
		}
    }

    reloadWithDelay (delay, el) {
		const _this = this;
		const form = $(el).closest('form');
	
        Timeout.setTimeoutAndClearPrevious(
            'cartRecalculator',
            function () {
                _this.reload(form);
            },
            delay
        );
    }

    static init ($container) {
        // eslint-disable-next-line no-new
        new CartRecalculator($container);
    }

}

(new Register()).registerCallback(CartRecalculator.init, 'CartRecalculator.init');