// UI for front
console.log('Front');

// Required dependencies
import 'jquery';
global.$ = global.jQuery = jQuery;

// Assets
import '../../../theme/front/style.scss';
import '../../../img/front/logo.png';
import '../../../img/front/kosik-prazdny.jpg';

import '../../components/naja';
import '../../components/netteForms';
import onDomReady from '../../utils/onDomReady';
import '../../components/naja-errorHandler';
import '../../components/fid-remover';
import {SpinnerExtension} from '../../components/naja-spinner';
import {ModalExtension} from '../../components/naja-modal';
import '../../components/bootstrap';
import '../../components/SearchBox';
import blueimp from 'blueimp-gallery';
import 'blueimp-gallery/js/blueimp-gallery-indicator';
import '../../utils/utils';

import '../../components/Spinbox';

import './cart';
import './product';

import Register from '../../utils/Register';

naja.registerExtension(new SpinnerExtension('body'));
naja.registerExtension(new ModalExtension('front'));
naja.formsHandler.netteForms = Nette;

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    (new Register()).registerNewContent($(event.detail.snippet));
});

naja.historyHandler.addEventListener('buildState', (event) => {
	if (event.detail.options.reloadOnRestore) {
		event.detail.state.reloadOnRestore = true;
	}
});

naja.historyHandler.addEventListener('restoreState', (event) => {
	if (event.detail.state.reloadOnRestore) {
		event.stopImmediatePropagation();
		naja.makeRequest('GET', event.detail.state.href, null, { history: false });
	}
});

onDomReady(() => {	
	
	/*
	NAJA
	history: false,
    forceRedirect: true,
	*/
	
	naja.initialize();

	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

	$('body').on('click', '.bi-gallery a:hrefImage', function(event) {
		event = event || window.event;
		var target = event.target || event.srcElement, link = this, options = {index: link, event: event};
		var links = $(this).closest('.bi-gallery').find('a:hrefImage');
		blueimp(links, options);
	});

	$('body').on('click', '.bi-p-gallery a[data-bi]:hrefImage', function(event) {
		event = event || window.event;
		var target = event.target || event.srcElement, link = this, options = {index: link, event: event};
		var links = $('.bi-p-gallery a[data-bi]');
		blueimp(links, options);
	});
    
    (new Register()).registerNewContent($('body'));
    	
});

// Webpack HMR
if (module.hot) {
	module.hot.accept();
}