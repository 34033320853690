const initBootstrapToasts = () => {
    let toastElList = [].slice.call(document.querySelectorAll('.toast:not([data-toast-init])'));
    let toastList = toastElList.map(function (toastEl) {
	    toastEl.addEventListener('show.bs.toast', function () {
			this.setAttribute('data-toast-init', true);
		});
		toastEl.addEventListener('hidden.bs.toast', function() {
			this.remove();
		});
        return new bootstrap.Toast(toastEl, {'animation': true, 'delay': 15000}).show();
    });
}

export default initBootstrapToasts;