import * as bootstrap from 'bootstrap';
import naja from 'naja';

/**

predelat na moznost v php nastavovat velikost modalu, tlacitka modalu, konfigurace modalu


// v sablone
<a href="" class="ajax" data-naja-history="off">Otevri modal</a>

// v akci
if ($this->isAjax()) {
	$this->payload->modal != false;
	$this->payload->modal = [
		
	];
	$this['komponenta']->setAjax(true);
	$this->redrawControl();
}

// ve zpracovani
if ($this->isAjax()) {
	$this->payload->modal = false;
	$this->payload->modalRefreshUrl = $this->link('Presenter:default');    		
	$this->redrawControl();
}

https://naja.js.org/#/redirection?id=redirect-event

*/
const ND_MODAL_ID = 'ajax-modal';
const ND_SNIPPET_NAME = 'modal';

export class ModalExtension {

	constructor(mode) {
        this.mode = mode;
    }
	initialize(naja) {
		this.isModalOpen = false;
		naja.addEventListener('start', this.start.bind(this));
        naja.addEventListener('complete', this.complete.bind(this));
        
        naja.snippetHandler.addEventListener('beforeUpdate', this.beforeUpdate.bind(this));
        
        
        naja.redirectHandler.addEventListener('redirect', (event) => {
		    //event.detail.setHardRedirect(true);
		    event.preventDefault();
		});
		
    }
    start() {
		if (document.querySelector('#' + ND_MODAL_ID + '.show') !== null) {
			this.isModalOpen = true;
		}
	}
    complete({detail}) {
        const payload = detail.payload;
		if (typeof payload !== 'undefined') {
	        if (typeof payload.modal !== 'undefined') {
	            var that = this;
	            
	            const modalEl = document.querySelector('#' + ND_MODAL_ID);
				this.modal = bootstrap.Modal.getOrCreateInstance(modalEl);
				
				modalEl.addEventListener('hidden.bs.modal', () => {
					that.modal.dispose();
					modalEl.remove();
					this.isModalOpen = false;
					
					if (typeof payload.modalRedirectUrl !== 'undefined') {
						window.location.href = payload.modalRedirectUrl;
					}
				});
				
				let modalFooter = modalEl.querySelector('.modal-footer');
				const submitFormRow = modalEl.querySelector('#submit-form-row');
				
				if (modalFooter == null && submitFormRow !== null) {
					submitFormRow.style.display = 'none';
					
					const modalBody = modalEl.querySelector('.modal-body');
					modalFooter = document.createElement('div');
					modalFooter.classList.add('modal-footer');
					
					submitFormRow.querySelectorAll('input[type="submit"]').forEach(el => {
						const cloneEl = el.cloneNode(true);
						cloneEl.addEventListener('click', function(e) {
							const originalEl = modalBody.querySelector('input[name="' + e.target.getAttribute('name') + '"]');
							if (originalEl !== null) {
								originalEl.click();
							}
						}, false);
						
						modalFooter.append(cloneEl);
					});
					
					modalBody.parentNode.insertBefore(modalFooter, modalBody.nextSibling);
				}
	
	            if (this.isModalOpen == false) {
					if (payload.modal !== false) {
						this.modal.show();
					}
				} else {
					let backdropEl = document.querySelector('.modal-backdrop');
					if (backdropEl) {
						this.modal._backdrop._element = backdropEl;
						this.modal._backdrop._isAppended = true;
					}
					this.modal._isShown = true;
				}
            
				if (typeof payload.modalRefreshUrl !== 'undefined') {
					modalEl.addEventListener('hidden.bs.modal', () => {
						naja.makeRequest('GET', payload.modalRefreshUrl);
					});
				}            
            
	            if (payload.modal == false ) {
					this.modal.hide();
				}
				
	        }
        }
    }
    
    beforeUpdate({detail}) {	
		if (detail.snippet.hasAttribute('id') && detail.snippet.getAttribute('id') == 'snippet--' + ND_SNIPPET_NAME && this.isModalOpen == true) {
			detail.changeOperation((snippet, content) => {
				content = document.createRange().createContextualFragment(content);
				const modalEl = content.querySelector('#' + ND_MODAL_ID);
				modalEl.classList.add('show');
				modalEl.style.display = 'block';
				
				const tmpEl = document.createElement('div');
				tmpEl.append(content);
				
				snippet.innerHTML = tmpEl.innerHTML;
			});			
		}
	}    
  
}