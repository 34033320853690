import Register from '../utils/Register';
import { KeyCodes } from '../utils/KeyCodes';

export default class Spinbox {

	constructor (el) {
		const _this = this;
		
        const $input = this.input = $(el).find('input.js-spinbox-input');
        const $plusButton = this.plusButton = $(el).find('.js-spinbox-plus');
        const $minusButton = this.minusButton = $(el).find('.js-spinbox-minus');

		$(el).data('spinbox', this);

		$input.on('paste drop input change focusout', function (event) {
			if (event.type === 'paste' || event.type === 'drop') {
				event.preventDefault();
			}
			
			if (event.type !== 'input') {
				_this.updateButtons();
			}
			
			if (event.type === 'input') {
				_this.checkValue();
			}
						
            if (event.type === 'focusout') {
                _this.resetTimer()
            }
		}).on('keydown', function (event) {
            if (event.which === KeyCodes.ARROW_UP) {
                event.preventDefault()
                if (!$plusButton.prop('disabled')) {
                    _this.stepHandling(1);
                }
            } else if (event.which === KeyCodes.ARROW_DOWN) {
                event.preventDefault()
                if (!$minusButton.prop('disabled')) {
                    _this.stepHandling(-1);
                }
            }
		}).on('keyup', function (event) {	
            if (event.which === KeyCodes.ARROW_UP || event.which === KeyCodes.ARROW_DOWN) {
                event.preventDefault();
                _this.resetTimer();
                _this.updateButtons();
            }
		});

        this.onPointerDown($minusButton[0], function () {
            if (!$minusButton.prop('disabled')) {
                _this.pointerState = true
                _this.stepHandling(-1)
            }
        });
	
		this.onPointerDown($plusButton[0], function () {
			if (!$plusButton.prop('disabled')) {
				_this.pointerState = true
				_this.stepHandling(1)
			}
		});
		
		this.onPointerUp(document.body, function () {
			if(_this.pointerState === true) {
				_this.resetTimer()
				_this.dispatchEvent(_this.input, 'change')
				_this.pointerState = false
			}
		});
		
		this.updateButtons();
	}

	dispatchEvent($element, type) {
	    if (type) {
	        setTimeout(function () {
	            let event
	            if (typeof (Event) === 'function') {
	                event = new Event(type, {bubbles: true})
	            } else { // IE
	                event = document.createEvent('Event')
	                event.initEvent(type, true, true)
	            }
	            $element[0].dispatchEvent(event)
	        })
	    }
	}
	
	checkValue() {
		let min = this.input.data('min');
		let max = this.input.data('max');
		let value = this.input.val();
		
		let newValue = value.replace(/\D/g, '');
		
		if (min !== undefined && newValue < min) {
			newValue = min;
		}		
		
		if (max !== undefined && newValue > max) {
			newValue = max;
		}
		
		this.input.val(newValue);
	}
	
	updateButtons() {
		let min = this.input.data('min');
		let max = this.input.data('max');
		let value = this.input.val();
		
		if (min !== undefined) {
			if (min == value) {
				this.minusButton.prop('disabled', true);
			} else {
				this.minusButton.prop('disabled', false);
			}
		}

		this.plusButton.prop('disabled', false);
		if (max !== undefined) {
			if (max == value) {
				this.plusButton.prop('disabled', true);
			}
		}
	}  
  
    setValue(newValue) {
		let min = this.input.data('min');
		let max = this.input.data('max');
	
        if (!isNaN(newValue) && newValue !== '') {
            newValue = parseFloat(newValue)
            
            if (min !== undefined) {
				newValue = Math.max(newValue, min);
			}
			
			if (max !== undefined) {
				newValue = Math.min(newValue, max);
			}

            this.input.val(newValue);
        }
    }  
  
	stepHandling(step) {
		const _this = this;
		this.calcStep(step);
        this.resetTimer();
		
		_this.autoDelayHandler = setTimeout(function () {
				_this.autoIntervalHandler = setInterval(function () {
				_this.calcStep(step)
			}, 100)
		}, 500);
	}

    calcStep(step) {
		let value = this.input.val();
        if (isNaN(value)) {
            value = 0;
        }
        
        this.setValue(Math.round(value / step) * step + step);
        this.dispatchEvent(this.input, 'input');
    }

    resetTimer() {
        clearTimeout(this.autoDelayHandler);
        clearTimeout(this.autoIntervalHandler);
    }
  
	onPointerUp(element, callback) {
		const _this = this;
        element.addEventListener('mouseup', function (e) {
            callback(e);
        });
        element.addEventListener('touchend', function (e) {
            callback(e)
        });
        element.addEventListener('keyup', function (e) {
            if ((e.keyCode === KeyCodes.SPACE || e.keyCode === KeyCodes.ENTER)) {
                _this.triggerKeyPressed = false;
                callback(e);
            }
        });
    }

    onPointerDown(element, callback) {
		const _this = this;
        element.addEventListener('mousedown', function (e) {
            if (e.button === 0) {
                e.preventDefault()
                callback(e);
            }
        });
        element.addEventListener('touchstart', function (e) {
            if (e.cancelable) {
                e.preventDefault()
            }
            callback(e);
        });
        element.addEventListener('keydown', function (e) {
            if ((e.keyCode === KeyCodes.SPACE || e.keyCode === KeyCodes.ENTER) && !_this.triggerKeyPressed) {
                _this.triggerKeyPressed = true;
                callback(e);
            }
        });
    }

    static init ($container) {
        $container.filterAllNodes('.js-spinbox').each(function(index, element) {
			new Spinbox(element);
		});
    }
}

(new Register()).registerCallback(Spinbox.init, 'Spinbox.init');