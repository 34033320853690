export default class Utils {
	static getContrastYIQ(hexcolor) {
	    var r = parseInt(hexcolor.substr(1,2),16);
	    var g = parseInt(hexcolor.substr(3,2),16);
	    var b = parseInt(hexcolor.substr(5,2),16);
	    var yiq = ((r*299)+(g*587)+(b*114))/1000;
	    return (yiq >= 128) ? 'black' : 'white';
	}
}

window.Utils = Utils;

jQuery.extend(jQuery.expr[':'], {
	hrefImage: function (el) {
		let imgSuff = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'webp'];
		let found = false;
		imgSuff.forEach(function(suff) {
			let tester = new RegExp('.' + suff + '$', 'gi');
			if (tester.test(el.getAttribute('href'))) {
				found = true;
				return;
			}
		});
		return found;
	}
});

jQuery.fn.filterAllNodes = function (selector) {
    const $result = jQuery(this).find(selector).addBack(selector);

    // .addBack() does not change .prevObject, so we need to do it manually for proper functioning of .end() method
    $result.prevObject = $result.prevObject.prevObject;

    return $result;
};