import Register from '../../../utils/Register';
import naja from 'naja';
import FormDataJson from "form-data-json-convert/dist/form-data-json.es6";

// https://github.com/shopsys/demoshop/blob/9941144f1e67d0b996153796d412434003f2ed0a/src/Shopsys/ShopBundle/Resources/scripts/frontend/product/productList.AjaxFilter.js#L108

export default class ProductFilter {

	constructor ($container) {
		const _this = this;

		$container.find('[name="_reset"]').on('click', function(e) {
			e.preventDefault();
			_this.resetFilter($container);
		});

		$container.find('[name="_submit"]').on('click', function (e) {
			e.preventDefault();
			_this.applyFilter($container);
		});

		$container.find(':checkbox').on('change', function (e) {
			_this.applyFilter($container);
		});
	}

	resetFilter($form) {
		/*
		const $minimalPrice = $form.find('[name="minimalPrice"]');
		const $maximalPrice = $form.find('[name="maximalPrice"]');

		$minimalPrice.val($minimalPrice.attr('min'));
		$maximalPrice.val($maximalPrice.attr('max'));

		$form.find(':checked').prop('checked', false);
		 */

		$form
			.find(':radio, :checkbox').prop('checked', false).end()
			.find('textarea, :text, select').val('');

		this.submitFilter($form);
	}

	applyFilter($form) {

		this.submitFilter($form);
	}

	submitFilter($form) {
		const values = FormDataJson.toJson($form[0], {
			skipEmpty: true,
			inputFilter: function (inputElement) {
				return inputElement.type !== 'hidden'
			}
		});

		const parameterName = $form.data('list-name') + '-filter';

		const data = [];
		data[parameterName] = [];
		Object.entries(values).forEach(entry => {
			const [key, value] = entry;
			data[parameterName][key] = value;
		})

		naja.makeRequest('GET', $form.data('filter-url'), data);
	}


	static init ($container) {
		const $form = $container.find('.js-product-filter');
		if ($form.length > 0) {
			new ProductFilter($form);
		}
	}

}

(new Register()).registerCallback(ProductFilter.init, 'ProductFilter.init');