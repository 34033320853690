function removeSearchParamInUrlAndHistory(name) {
	const url = new URL(window.location.toString());
	if (!url.searchParams.has(name)) {
		return;
	}

	url.searchParams.delete(name);
	window.history.replaceState({}, null, url.toString());
}

setTimeout(() => removeSearchParamInUrlAndHistory('_fid'), 2000);