import Register from '../../../utils/Register';
import naja from 'naja';

/**

Musí být div.js-cart-transport-payments který obaluje celou komponentu.
Jednotlivé itemy s [data-url] a [data-transport] nebo [data-payment].
Uvnitř jednotlivých itemů musí být input:checkbox name transport nebo payment.

class:
	item: .tp-list__item
	+ aktivní item: .tp-list__item--active
	+ zablokovaný item: .tp-list__item--inactive

 */
export default class TransportPaymentChooser {

    constructor ($container) {
        const _this = this;
        this.transportPaymentRelations = {};

		$container.filterAllNodes('.js-cart-transport-payments :checkbox').each(function(i, el) {
			$(this).data('checked', $(this).prop('checked'));
		});
		
        $container.filterAllNodes('.js-cart-transport-payments [data-url]').on('click', function(e) {
			if ($(this).is('.tp-list__item--inactive') || $(e.target).is('a')) {
				e.preventDefault();
				return;
			}

			const checkbox = $(this).find(':checkbox');
		
			if ($(e.target).is(':checkbox') == false) {
				checkbox.prop('checked', checkbox.prop('checked') ? false : true) ;
			} else {
				checkbox.prop('checked', checkbox.data('checked') ? false : true);
			}
			checkbox.data('checked', checkbox.prop('checked'));

			_this.toggle(checkbox);
		});
			
		const transportPaymentRelations = $('.js-cart-transport-payments');
		transportPaymentRelations.data('relations').forEach(item => {
			this.addTransportPaymentRelation(item.transportId, item.paymentId);
		});

		$container.find('#packeta-selector').on('click', function(e) {
			e.preventDefault();
			_this.packetaOpen();
		});
    }
    
    toggle (checkbox) {
		
		if (checkbox.is('[name="transport"]')) {
			const currentTransport = $('.js-cart-transport-payments [name="transport"]:checked').not(checkbox);
			if (currentTransport.length > 0) {
				currentTransport.closest('[data-transport]').removeClass('tp-list__item--active');
				currentTransport.prop('checked', false).data('checked', false);
			}
			
			if (checkbox.prop('checked')) {
				checkbox.closest('[data-transport]').addClass('tp-list__item--active');
			} else {
				checkbox.closest('[data-transport]').removeClass('tp-list__item--active');
			}
					
			naja.makeRequest('GET', checkbox.closest('[data-transport]').data('url'), null, {
				history: false
			}).then(() => {
				if (currentTransport.length > 0) {
					currentTransport.closest('[data-transport]').addClass('tp-list__item--active');
					currentTransport.prop('checked', true).data('checked', true);
				}
				
				if (checkbox.prop('checked')) {
					checkbox.closest('[data-transport]').removeClass('tp-list__item--active');
					checkbox.prop('checked', false).data('checked', false);

					this.transportPicker(checkbox.closest('[data-transport]').data('shipping-service'));
				} else {
					checkbox.closest('[data-transport]').addClass('tp-list__item--active');
					checkbox.prop('checked', true).data('checked', true);
				}
			});
		} else {
			const currentPayment = $('.js-cart-transport-payments [name="payment"]:checked').not(checkbox);
			if (currentPayment.length > 0) {
				currentPayment.closest('[data-payment]').removeClass('tp-list__item--active');
				currentPayment.prop('checked', false).data('checked', false);
			}
			
			if (checkbox.prop('checked')) {
				checkbox.closest('[data-payment]').addClass('tp-list__item--active');
			} else {
				checkbox.closest('[data-payment]').removeClass('tp-list__item--active');
			}
					
			naja.makeRequest('GET', checkbox.closest('[data-payment]').data('url'), null, {
				history: false
			}).then(() => {
				if (currentPayment.length > 0) {
					currentPayment.closest('[data-payment]').addClass('tp-list__item--active');
					currentPayment.prop('checked', true).data('checked', true);
				}
				
				if (checkbox.prop('checked')) {
					checkbox.closest('[data-payment]').removeClass('tp-list__item--active');
					checkbox.prop('checked', false).data('checked', false);
				} else {
					checkbox.closest('[data-payment]').addClass('tp-list__item--active');
					checkbox.prop('checked', true).data('checked', true);
				}
			});			
		}

    }
    
    addTransportPaymentRelation(transportId, paymentId) {
        if (this.transportPaymentRelations[transportId] === undefined) {
            this.transportPaymentRelations[transportId] = {};
        }
        this.transportPaymentRelations[transportId][paymentId] = true;
    }

    transportPaymentRelationExists(transportId, paymentId) {
        if (this.transportPaymentRelations[transportId] !== undefined) {
            if (this.transportPaymentRelations[transportId][paymentId] !== undefined) {
                return this.transportPaymentRelations[transportId][paymentId];
            }
        }

        return false;
    }    
    
    updateTransportPaymentRelations() {
		const _this = this;
		const $transport = $('.js-cart-transport-payments [name="transport"]:checked').closest('[data-transport]');
		const $payment = $('.js-cart-transport-payments [name="payment"]:checked').closest('[data-payment]');
		
		if ($transport.length > 0) {
			$('.js-cart-transport-payments [data-payment]').each(function(i, el) {
				if (_this.transportPaymentRelationExists($transport.data('transport'), $(el).data('payment'))) {
                    $(el).find(':checkbox').prop('disabled', false);
                    $(el).removeClass('tp-list__item--inactive');
                } else {
                    $(el).find(':checkbox').prop('disabled', true);
                    $(el).addClass('tp-list__item--inactive');
                }
			});
		} else {
			$('.js-cart-transport-payments [data-payment]').each(function(i, el) {
                $(el).find(':checkbox').prop('disabled', false);
                $(el).removeClass('tp-list__item--inactive');
			});			
		}
		
		if ($payment.length > 0) {
			$('.js-cart-transport-payments [data-transport]').each(function(i, el) {
				if (_this.transportPaymentRelationExists($(el).data('transport'), $payment.data('payment'))) {
                    $(el).find(':checkbox').prop('disabled', false);
                    $(el).removeClass('tp-list__item--inactive');
                } else {
                    $(el).find(':checkbox').prop('disabled', true);
                    $(el).addClass('tp-list__item--inactive');
                }
			});
		} else {
			$('.js-cart-transport-payments [data-transport]').each(function(i, el) {
                $(el).find(':checkbox').prop('disabled', false);
                $(el).removeClass('tp-list__item--inactive');
			});			
		}
	}

	transportPicker(service) {
		if (typeof packetaService !== 'undefined' && service === packetaService) {
			this.packetaOpen();
		}
	}

	packetaOpen() {
		Packeta.Widget.pick(packetaApiKey, this.packetaCallback, packetaOptions);
	}

	packetaCallback(point) {
		if (point === null) {
			const transportEl = document.querySelector('[data-transport][data-selected]');
			if (transportEl) {
				transportEl.click();
			}
		} else {
			/*
			const packetaPointEl = document.querySelector('#packeta-point');
			packetaPointEl.innerText = point.place + ', ' + point.street + ', ' + point.zip + ' ' + point.city;
			*/
			const pickupPointUrl = document.querySelector('.js-cart-transport-payments').dataset.pickupPointUrl;
			naja.makeRequest('POST', pickupPointUrl, {
				point: point
			}, {
				history: false
			});
		}
	}

    static init ($container) {
		if ($container.find('.js-cart-transport-payments').length > 0) {
	        const transportPaymentChooser = new TransportPaymentChooser($container);
	        transportPaymentChooser.updateTransportPaymentRelations();
        }
        
    }

}

(new Register()).registerCallback(TransportPaymentChooser.init, 'TransportPaymentChooser.init');