import onDomReady from '../utils/onDomReady';

export class SpinnerExtension {
	constructor(fallbackSelector) {
		this.fallbackSelector = fallbackSelector;
	}

	initialize(naja) {
		onDomReady(() => {
			const mainContent = document.querySelector(this.fallbackSelector);

			naja.uiHandler.addEventListener('interaction', (event) => {
				event.detail.options.spinnerTarget = event.detail.element.closest('[data-spinner]');
			});

			naja.addEventListener('start', (event) => {
				if (event.detail.options.spinner !== false) {
					(event.detail.options.spinnerTarget ?? mainContent).classList.add('spinner')
				}
			});
			naja.addEventListener('complete', (event) => (event.detail.options.spinnerTarget ?? mainContent).classList.remove('spinner'));
		});
	}
}
