import naja from 'naja';
import netteForms from 'nette-forms';
window.Nette = netteForms;

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	Nette.initOnLoad();
});

Nette.validators['AppUIFormValidator_validateContain'] = (elem, args, val) => {	
	if (Number.isInteger(args)) {
		args = args.toString();
	}

	if ((elem.tagName == 'SELECT' && elem.hasAttribute('multiple')) ||
		(elem.tagName == 'INPUT' && elem.getAttribute('type') == 'checkbox' && document.querySelectorAll('input[name="' + elem.getAttribute('name') + '"]').length > 1)) {
		if (Array.isArray(args)) {
			let isValid = true;
			args.forEach((el) => {
				if (Number.isInteger(el)) {
					el = el.toString();
				}
				if (val.includes(el) === false) {
					isValid = false;
					return;
				}
			});
			return isValid;
		} else {
			if (elem.tagName == 'INPUT' && elem.value !== args) return true;
			return val.includes(args);				
		}		
	}
	return true;
};